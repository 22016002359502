import { useState } from "react";

import Button from "components/button";

import StakingItemStyleWrapper from "./NftStaking.style";

import NFTStakingComponent from "./NFTStakingComponent";

import { useContractRead, useAccount, useContractWrite } from 'wagmi'

import NftABI from "assets/data/abi/NftABI";
import kittpadABI from "assets/data/abi/kittpadABI";
// import TestKittpadABI from "assets/data/abi/TestKittpadABI";


const NFTStaking = ({
    active
}) => {
    const { isConnected, address } = useAccount()

    const [selectedStake, setSelectedStake] = useState([])
    const [selectedUnstake, setSelectedUnstake] = useState([])

    const ReadDataStake = useContractRead({
        address: '0x46084F00dD87B2f50c1E898399241E760D2284E3',
        abi: NftABI,
        functionName: 'walletOfOwner',
        args: [address],
        watch: true,
        onSuccess(data) {
            console.log("Ids", data)
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const ReadDataUnstake = useContractRead({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'getBoostNfts',
        args: [address],
        watch: true,
        onSuccess(data) {
            console.log("Ids", data)
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const ReadData2 = useContractRead({
        address: '0x46084F00dD87B2f50c1E898399241E760D2284E3',
        abi: NftABI,
        functionName: 'isApprovedForAll',
        args: [address, '0xea36def1F65c9a807F7b5b26ef26000106895668'],
        watch: true,
        onError(error) {
            console.log('Read Data 2 Error', error)
        },
        onSuccess(data) {
            console.log('Read Data 2 Success', data)
        },
    })

    const WriteData3 = useContractWrite({
        address: '0x46084F00dD87B2f50c1E898399241E760D2284E3',
        abi: NftABI,
        functionName: 'setApprovalForAll',
        args: ['0xea36def1F65c9a807F7b5b26ef26000106895668', true],
        onError(error) {
            console.log('Write Error', error)
            console.log("Selected Stake", selectedStake)
        },
        onSuccess(data) {
            console.log('Write Success', data)
            WriteData1.write()
        },
    })

    const WriteData1 = useContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'stakeNFT',
        args: [selectedStake],
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })

    const WriteData2 = useContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'unstakeNFT',
        args: [selectedUnstake],
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })

    const handleStake = (e) => {
        e.preventDefault();
        if (ReadData2.data === true) {
            // console.log("Selected Staked", selectedStake)
            WriteData1.write()
        }
        else if (ReadData2.data === false) {
            // console.log("no")
            // console.log("Selected Staked", selectedStake)
            WriteData3.write()
        }
    }

    const handleUnstake = (e) => {
        e.preventDefault();
        // console.log("Selected Unstaked", selectedUnstake)
        WriteData2.write()
    }



    return (
        <StakingItemStyleWrapper>
            <div className="container">
                <div className="row items">
                    {!isConnected && <div className="col-12 col-sm-6 col-lg-3 item">
                        <h1 className="nftFail">No Wallet Connected</h1>
                    </div>}
                    {isConnected && (active ? (ReadDataStake?.data !== undefined && ReadDataStake?.data.length !== 0 ?
                        <>
                            {ReadDataStake?.data?.map((item, index) => (
                                <div className="col-12 col-sm-6 col-lg-3 item stakeCard">
                                    <NFTStakingComponent id={Number(item)} selected={selectedStake} handleSelect={() => {
                                        if (selectedStake.includes(item)) {
                                            setSelectedStake(selectedStake.filter((i) => i !== item))
                                        } else {
                                            setSelectedStake([...selectedStake, item])
                                        }
                                    }} />
                                </div>
                            ))}
                            {selectedStake.length === 0 ? 
                                <h2 className="nftFail">Nothing Selected</h2>
                                :
                                <Button className="action-button" onClick={handleStake}>Stake</Button>
                            }
                            
                        </> : <div className="col-12 col-sm-6 col-lg-3 item">
                            <h2 className="nftFail">No NFT's to Display</h2>
                        </div>
                    ) : (ReadDataUnstake?.data !== undefined && ReadDataUnstake?.data.length !== 0 ?
                        <>
                            {ReadDataUnstake?.data?.map((item, index) => (
                                <div className="col-12 col-sm-6 col-lg-3 item">
                                    <NFTStakingComponent id={Number(item)} selected={selectedUnstake} handleSelect={() => {
                                        if (selectedUnstake.includes(item)) {
                                            setSelectedUnstake(selectedUnstake.filter((i) => i !== item))
                                        } else {
                                            setSelectedUnstake([...selectedUnstake, item])
                                        }
                                    }} />
                                </div>
                            ))}
                            {selectedUnstake.length === 0 ? 
                                <h2 className="nftFail">Nothing Selected</h2>
                                :
                                <Button className="action-button" onClick={handleUnstake}>Unstake</Button>
                            }
                        </> : <div className="col-12 col-sm-6 col-lg-3 item">
                            <h2 className="nftFail">No NFT's to Display</h2>
                        </div>)
                    )}

                </div>
            </div>
        </StakingItemStyleWrapper >
    )
}

export default NFTStaking;
