import { Routes, Route } from "react-router-dom";
import HomeThree from "pages/home-three";
import StakingThree from "pages/staking-3";
import Leaderboard from "pages/leaderboard";
import Contact from "pages/contact";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";

import { mainnet } from "wagmi/chains";

import ContextProvider from "utils/ContextProvider";

import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import WalletModal from "components/modal/walletModal/WalletModal";


const chains = [mainnet];
const projectId = "d9914d65e833c8cb44de178573bd447f";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);


function App() {

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <ContextProvider>
          <Routes>
            <Route path="/" element={< HomeThree />} exact />
            {/* <Route path="/staking-3" element={<StakingThree />} /> */}
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </ContextProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>


  );
}

export default App;
