import styled from "styled-components";

const IncubatorListItem2StyleWrapper = styled.div`
    .master {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #040912;
        text-align: left;
        width: 100%;
        height: 250px;
        padding: 20px 20px;
        border-radius: 10px;

        .categoryTitle{
            font-size: 18px;
            color: white;
        }

        .value{
            color: #feac53;
            font-size: 15px;
        }

        .child1{
            line-height: 40px;
        }

        .child2{
            line-height: 40px;
        }


    }

    .claimValue{
        display: flex;
        justify-content: space-between;
    }

    .claimButton{
        background: #feac53;
        color: white;
        border: none;
        outline: none;
        border-radius: 10px;
        padding: 0px 30px;
        margin-bottom: 10px;
    }

    .claimButton:hover {
        background-color: black;
        transition-duration: 0.3s;
    }

    .unclaimed{
        color: white;
        width: 100%;
        height: 100px;
        background: #040912;
        padding: 35px 20px 20px 20px;
        border-radius: 10px;
        
    }
`;

export default IncubatorListItem2StyleWrapper;