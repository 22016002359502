import ClaimListItem1StyleWrapper from "./ClaimListItem1.style";
import { useContractRead,} from 'wagmi'

import { useAccount } from 'wagmi'

const ClaimListItem1 = ({
    title,
    value,
    icon,
    functionName,
    args,
    abi,
    contractAddress
}) => {

    const { isConnected, address } = useAccount()

    if(!args) args = [address]

    const { data } = useContractRead({
        address: contractAddress,
        abi: abi,
        functionName: functionName,
        args: args,
        watch: true,
        onSuccess(data) {
            if(functionName === 'balanceOf') {
                console.log("NFT's Owned", data)
            }
            else if(functionName === 'totalClaimed'){
                console.log('Total Claimed', data)
            }
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    if(isConnected || localStorage.getItem('isWalletConnected')){
        value = Number(data).toString()
    }
    
    return (
        <ClaimListItem1StyleWrapper>
            <div className="master1">
                <div className="divIcon">
                    <img src={icon} alt="icon" height="80px" width="80px"></img>
                </div>
                <div className="master">
                    <div className="child1">
                        <p>{title}</p>
                        <p>{value}</p>
                    </div>
                </div>
            </div>
        </ClaimListItem1StyleWrapper>
    )
}

export default ClaimListItem1;