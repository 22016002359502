import styled from "styled-components";

const StakingListStyleWrapper = styled.div`
  padding-bottom: 110px;

  .staking_items_row {
    row-gap: 30px;
  }

  .staking_flip_card_front_buttons {
    align-items: left;
    justify-content: space-around;
    margin-bottom: 20px;
    

    .new-button-active{
      background: #FEAC53;
      padding: 20px;
      font-family: "Russo One", sans-serif;
      color:white;
    }

    .new-button{
      background: transparent;
      padding: 20px;
      font-family: "Russo One", sans-serif;
      color:white;
    }

    .new-button:hover{
      background: gray;
      transition-duration: 0.4s;
    }

  }

  .nftStaking{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .coin_cap_list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    li {
      width: 80%;

      h5 {
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 0px;
        font-size: 24px;
        overflow: hidden
      }

      .counter-number {
        font-family: "Russo One";
        font-style: normal;
        font-weight: normal;
        font-size: 34px;
        line-height: 40px;
        text-transform: uppercase;
        color: #E38241;
      }
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 90px;
  }
`;

export default StakingListStyleWrapper;
