import kittpadABI from "assets/data/abi/kittpadABI";
import StakingItem from "./StakingItem/StakingItem"
import StakingListStyleWrapper from "./StakingList.style"
import data from "assets/data/staking/dataV1"
import { useAccount, useContractRead } from 'wagmi'
import Counter from "components/counter";
import { useState, useEffect } from "react"
import NFTStaking from "sections/NFTStakingPage/v3/NftStaking";

const StakingList = () => {
    const [totalMeowStaked, setTotalMeowStaked] = useState(0);
    const [yourMeowStaked, setYourMeowStaked] = useState(0);
    const { address, isConnected } = useAccount()

    const [tokenActive, isTokenActive] = useState(true);
    const [nftActive, isNftActive] = useState(true);

    const handleClick = (e) => {
        e.preventDefault();
        isTokenActive(!tokenActive);
    }

    const handleClickNft = (e) => {
        e.preventDefault();
        isNftActive(!nftActive);
    }

    const ReadData1 = useContractRead({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'totalSharesBoosted',
        watch: true,
        onSuccess(data) {
            setTotalMeowStaked(Number(data) / (10 ** 9));
        },
        onError(error) {
            console.log('error', error)
        },
    });

    const ReadData2 = useContractRead({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'getShares',
        args: [address],
        watch: true,
        onSuccess(data) {
            setYourMeowStaked(Number(data) / (10 ** 9));
        },
        onError(error) {
            console.log('error', error)
        },
    });


    useEffect(() => {
        // Trigger the contract data fetch when the component mounts
        const result = [Number(ReadData1?.data)];
        const result2 = [Number(ReadData2?.data)];
    }, []);

    return (
        <StakingListStyleWrapper>
            <div className="container">
                <div className="row staking_items_row">
                    <div className="staking_flip_card_front_buttons">
                        {tokenActive ?
                            <>
                                <button className="new-button-active" href="# " onClick={handleClick}>
                                    STAKE
                                </button>
                                <button className="new-button" href="# " onClick={handleClick}>
                                    UNSTAKE
                                </button>
                            </> : <>
                                <button className="new-button" href="# " onClick={handleClick}>
                                    STAKE
                                </button>
                                <button className="new-button-active" href="# " onClick={handleClick}>
                                    UNSTAKE
                                </button>
                            </>
                        }
                    </div>
                    {data?.map((stake, i) => (
                        <div key={i} className="col-lg-4 col-md-4">
                            <StakingItem {...stake} active={tokenActive} />
                        </div>
                    ))}
                    <div className="col-lg-4 col-md-4"></div>
                    <ul className="coin_cap_list col-lg-4 col-md-4">
                        <li>
                            <h5>Total Meow Supply</h5>
                            <h6 className="counter-number">
                                <Counter
                                    end={1.00}
                                    decimal="."
                                    decimals={490.88 % 1 !== 0 ? "2" : "0"}
                                    suffix="T"
                                /></h6>
                        </li>
                        <li>
                            <h5>Total Meow Staked</h5>
                            <h6 className="counter-number">
                                <Counter
                                    end={totalMeowStaked}
                                    decimal="."
                                    decimals={490.88 % 1 !== 0 ? "2" : "0"}
                                    suffix=""
                                /></h6>
                        </li>
                        <li className="m-0">
                            <h5>Staking Pool %</h5>
                            <h6 className="counter-number">
                                <Counter
                                    end={yourMeowStaked / totalMeowStaked * 100}
                                    decimal="."
                                    decimals={490.88 % 1 !== 0 ? "2" : "0"}
                                    suffix="%"
                                /> </h6>
                        </li>
                    </ul>
                </div>
                <div className="staking_flip_card_front_buttons">
                        {nftActive ?
                            <>
                                <button className="new-button-active" href="# " onClick={handleClickNft}>
                                    STAKE
                                </button>
                                <button className="new-button" href="# " onClick={handleClickNft}>
                                    UNSTAKE
                                </button>
                            </> : <>
                                <button className="new-button" href="# " onClick={handleClickNft}>
                                    STAKE
                                </button>
                                <button className="new-button-active" href="# " onClick={handleClickNft}>
                                    UNSTAKE
                                </button>
                            </>
                        }
                    </div>
                <h2 className="nftStaking">NFT Staking</h2>
            </div>

            <NFTStaking active={nftActive}/>
        </StakingListStyleWrapper>
    )
}

export default StakingList;