import logo1 from "assets/images/partner/Logo_black_png.png";
import logo2 from "assets/images/partner/IT-Logo.png";
const data = [
  {
    partnerType: "PROJECTS",
    logos: [
      { image: logo1, url: 'https://vetmeblock.com/' },
      { image: logo2, url: 'https://buyit.finance/' },
    ],
  },
];

export default data;
