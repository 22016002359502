import PageSelectorStyleWrapper from "./PageSelector.style";
import { useModal } from "utils/ModalContext";

const PageSelector = () => {
    const { handlePageChange, page } = useModal();
    
    const handlePageChangeLocal = (e, page) => {
        e.preventDefault();
        handlePageChange(page);
    }

    return (
        <PageSelectorStyleWrapper>
            <div className="encloser">
                <div className="container">
                    <div className="selector-buttons">
                        {page === 'claim' ? <>
                            <button className="active" href="#" onClick={(e) => handlePageChangeLocal(e, "claim")}>Claim</button>
                        </> : 
                        <>
                            <button className="inactive" href="#" onClick={(e) => handlePageChangeLocal(e, "claim")}>Claim</button>
                        </>
                        }
                        {page === 'incubator' ? <>
                            <button className="active" href="#" onClick={(e) => handlePageChangeLocal(e, "incubator")}>Incubator</button>
                        </> : 
                        <>
                            <button className="inactive" href="#" onClick={(e) => handlePageChangeLocal(e, "incubator")}>Incubator</button>
                        </>
                        }
                        {page === 'staking' ? <>
                            <button className="active" href="#" onClick={(e) => handlePageChangeLocal(e, "staking")}>Staking</button>
                        </> : 
                        <>
                            <button className="inactive" href="#" onClick={(e) => handlePageChangeLocal(e, "staking")}>Staking</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </PageSelectorStyleWrapper>
    )
}

export default PageSelector;