/* global BigInt */

import { useState, forwardRef } from "react";
// import Button from "components/button";

import { useContractRead } from 'wagmi'

import NftABI from "assets/data/abi/NftABI";
// import kittpadABI from "assets/data/abi/kittpadABI";
// import TestKittpadABI from "assets/data/abi/TestKittpadABI";
// import { set } from "date-fns";

const NFTStakingComponent = forwardRef(({ id, selected, handleSelect }) => {

    const [nftData, setNftData] = useState([])

    useContractRead({
        address: '0x46084F00dD87B2f50c1E898399241E760D2284E3',
        abi: NftABI,
        functionName: 'tokenURI',
        args: [id],
        watch: true,
        async onSuccess(data) {
            const ipfsUrl = uriToIpfsURL(data)
            const ipfsResp = await fetch(ipfsUrl)
            const metadata = await ipfsResp.json()
            metadata.image = uriToIpfsURL(metadata.image)
            setNftData([id, metadata.image, metadata.name])
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const uriToIpfsURL = (uri) => {
        const uriPrefix = ["ipfs:://", "ipfs://"]
        let ipfsUrl = uri;
        for (let i in uriPrefix) {
            const prx = uriPrefix[i]
            // console.log("[DAVID] prx = ", prx)
            if (uri.startsWith(prx)) {
                ipfsUrl = "https://ipfs.io/ipfs/" + uri.slice(prx.length)
                break
            }
        }
        return ipfsUrl;
    }

    return (
        <div>
            {
                !selected.includes(BigInt(id)) ?
                    <div className="card" onClick={handleSelect}>
                        <div className="image-over">
                            <img className="card-img-top" src={nftData[1]} alt="" />
                        </div>
                        <div className="card-caption col-12 p-0">
                            <div className="card-body">
                                <h5 className="title">{nftData[2]}</h5>
                            </div>
                        </div>
                    </div> :
                    <div className="card selected" onClick={handleSelect}>
                        <div className="image-over">
                            <img className="card-img-top" src={nftData[1]} alt="" />
                        </div>
                        <div className="card-caption col-12 p-0">
                            <div className="card-body">
                                <h5 className="title">{nftData[2]}</h5>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
});

export default NFTStakingComponent;