import kittpadAbi from "assets/data/abi/kittpadABI";
import NftABI from "../abi/NftABI";
// import NftABI from "assets/data/abi/NftABI";

// KITPADD CONTRACT 0xea36def1F65c9a807F7b5b26ef26000106895668
// NFT CONTRACT 0x46084F00dD87B2f50c1E898399241E760D2284E3
// TOKEN CONTRACT 0x74d2d73B455540B037298c0e0925BC702AEDbE4a

const dataOther = [
    {
        'title': 'Balance',
        'category1Title': 'Unstaked NFTs',
        'value1': 'Connect Wallet',
        'category2Title': 'Staked NFTs',
        'value2': 'Connect Wallet',
        'function2Name': 'getBoostNfts',
        'abi2': kittpadAbi,
        'address2': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'function1Name': 'balanceOf',
        'abi1': NftABI,
        'address1': '0x46084F00dD87B2f50c1E898399241E760D2284E3',
    },

    {
        'title': 'Percentage',
        'category1Title': '% Supply',
        'value1': 'Connect Wallet',
        'category2Title': 'Staked NFTs',
        'value2': 'Connect Wallet',
        'function2Name': 'getBoostNfts',
        'abi2': kittpadAbi,
        'address2': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'function1Name': 'totalNftStaked',
        'abi1': kittpadAbi,
        'address1': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'args1': []
    },

    {
        'title': 'Unclaimed',
        'category1Title': 'Reflections',
        'value1': 'Connect Wallet',
        'category2Title': 'Staking Rewards',
        'value2': 'Connect Wallet',
        'function2Name': 'getUnpaidEth',
        'abi2': kittpadAbi,
        'address2': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'function1Name': 'getUnpaidEth',
        'abi1': kittpadAbi,
        'address1': '0xea36def1F65c9a807F7b5b26ef26000106895668',
    }
]

export default dataOther;