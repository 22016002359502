import facebook from "assets/images/icons/facebook.svg";
import discord from "assets/images/icons/discord.svg";
import insta from "assets/images/icons/insta.svg";
import linkedin from "assets/images/icons/linkedin.svg";
import medium from "assets/images/icons/medium.svg";
import telegram from "assets/images/icons/telegram.svg";
import twitter from "assets/images/icons/twitter.svg";
const data = [
  {
    icon: discord,
    url: "https://discord.com/invite/evHSXcgba9",
  },
  {
    icon: insta,
    url: "https://www.instagram.com/theschrodingerofficial/?utm_medium=copy_link",
  },
  {
    icon: telegram,
    url: "https://t.me/Schrodingercommunity",
  },
  {
    icon: twitter,
    url: "https://twitter.com/KittyDinger?s=20",
  },
];

export default data;
