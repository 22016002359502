import { useState } from "react"

import StakingItemStyleWrapper from "./StakingItem.style";

import { useContractWrite,useAccount,useContractRead } from 'wagmi'
import kittpadABI from "assets/data/abi/kittpadABI";
import MeowTokenABI from "assets/data/abi/MeowTokenABI";
import { formatUnits,parseUnits } from 'viem'

const StakingItem = ({
    title,
    active
}) => {

    const [value, setValue] = useState(0);
    const { address, isConnected } = useAccount()

    const [abi, setAbi] = useState(MeowTokenABI);
    const [Abiaddress, setAbiAddress] = useState('0x74d2d73B455540B037298c0e0925BC702AEDbE4a');
    const [functionName, setFunctionName] = useState('approve');
    const [args, setArgs] = useState(['0xea36def1F65c9a807F7b5b26ef26000106895668', value * (10 ** 9)]);

    const handleMaxButtonClick = async(event) => {
        event.preventDefault();
        const result = [(ReadData1?.data)];
        if (result==0){
            setValue(0);
        } else {
            const EthValue = formatUnits(result, 9) 
            setValue(EthValue);
        }
        
    };

    const handleUnstakeMaxButtonClick = async(event) => {
        event.preventDefault();
        const result = [(ReadData2?.data)];
        if (result==0){
            setValue(0);
        } else {
            const EthValue = formatUnits(result, 9) 
            setValue(EthValue);
        }
        
    };

    const handleChange = (event) => {
        setValue(event.target.value);
        // console.log("Value", value);
    };

    const ReadData1 = useContractRead({
        address: Abiaddress,
        abi: abi,
        functionName: "balanceOf",
        args: [address],
        watch: true,
        onSuccess(data) {
            console.log("dat",data)
        },
        onError(error) {
            console.log('error', error)
        },
    })

    const ReadData2 = useContractRead({
        address: "0xea36def1F65c9a807F7b5b26ef26000106895668",
        abi: kittpadABI,
        functionName: "getShares",
        args: [address],
        watch: true,
        onSuccess(data) {
            console.log("bal1",data)
        },
        onError(error) {
            console.log('bal2', error)
        },
    })

    const ReadAllowance = useContractRead({
        address: "0x74d2d73B455540B037298c0e0925BC702AEDbE4a",
        abi: MeowTokenABI,
        functionName: "allowance",
        args: [address,"0xea36def1F65c9a807F7b5b26ef26000106895668"],
        watch: true,
        onSuccess(data) {
            console.log("bal1",data)
        },
        onError(error) {
            console.log('bal2', error)
        },
    })

    const WriteStake = useContractWrite({
        address: "0xea36def1F65c9a807F7b5b26ef26000106895668",
        abi: kittpadABI,
        functionName: "stake",
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })


    const WriteData1 = useContractWrite({
        address: Abiaddress,
        abi: abi,
        functionName: functionName,
        args: args,
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
            WriteStake.write({
                                    args: [parseUnits(value, 9)]
                                })
        },
    })

    const WriteData2 = useContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'unstake',
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })


    if (active) {
        return (
            <StakingItemStyleWrapper>
                <div className={`staking_flip_card_inner`}>
                    <div className="staking_flip_card_front">
                        <div className="staking_flip_card_front_headings">
                            <h2>{title}</h2>
                            {/* <span><img src={icon} alt="icon" /></span> */}
                        </div>
                        <div className="staking_flip_card_front_body">
                            <form className="">
                                <span className="amount">Amount</span>
                                <div className="">
                                    <input type="text" placeholder="0" className="input" onChange={handleChange} value={value} />
                                </div>
                                <button className="max" onClick={handleMaxButtonClick}>
                                Max
                            </button>
                            </form>
                            <button disabled={!WriteData1.write} onClick={() => {
                            const resultallowance = [(ReadAllowance?.data)];
                                console.log("Allowance ",resultallowance);
                                let compare = 0;
                                if (value !=0){
                                    compare = parseUnits(value, 9);
                                }
                                if (resultallowance >= compare) {
                                    WriteStake.write({
                                    args: [compare]
                                })
                                } else {
                                    WriteData1.write({
                                    args: ['0xea36def1F65c9a807F7b5b26ef26000106895668', compare]
                                })
                                }
                                
                            }} className="action-button" href="#">
                                Stake
                            </button>
                        </div>
                    </div>
                </div>
            </StakingItemStyleWrapper>
        )
    }
    else {
        return (
            <StakingItemStyleWrapper>
                <div className={`staking_flip_card_inner`}>
                    <div className="staking_flip_card_front">
                        <div className="staking_flip_card_front_headings">
                            <h2>{title}</h2>
                        </div>
                        <div className="staking_flip_card_front_body">
                            <form className="">
                                <span className="amount">Amount</span>
                                <div className="">
                                    <input type="text" placeholder="0" className="input" onChange={handleChange} value={value} />
                                </div>
                                <button className="max" onClick={handleUnstakeMaxButtonClick}>
                                Max
                            </button>
                            </form>
                            <button disabled={!WriteData2.write} onClick={() => {
                                let compare = 0;
                                if (value !=0){
                                    compare = parseUnits(value, 9);
                                }
                            WriteData2.write({
                                args: [compare]
                            })
                            }} className="action-button" href="# ">
                                Unstake
                            </button>
                        </div>
                    </div>
                </div>
            </StakingItemStyleWrapper>
        )
    }
}

export default StakingItem;
