import stakeIcon1 from "assets/images/icons/meow.png"
import NftABI from "assets/data/abi/NftABI";
import kittpadABI from "assets/data/abi/kittpadABI";


// KITPADD CONTRACT 0xea36def1F65c9a807F7b5b26ef26000106895668
// NFT CONTRACT 0x46084F00dD87B2f50c1E898399241E760D2284E3
// TOKEN CONTRACT 0x74d2d73B455540B037298c0e0925BC702AEDbE4a

const Claimdata = [
    {
        'title': 'Your NFTs',
        'value': 'Connect Wallet',
        'icon': stakeIcon1,
        'functionName': 'balanceOf',
        'abi': NftABI,
        'contractAddress': '0x46084F00dD87B2f50c1E898399241E760D2284E3',
    },

    {
        'title': 'Total Claimed',
        'value': 'Connect Wallet',
        'icon': stakeIcon1,
        'functionName': 'totalClaimedEth',
        'abi': kittpadABI,
        'contractAddress': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'args': []
    },

    {
        'title': 'Unclaimed Rewards',
        'value': 'Connect Wallet',
        'icon': stakeIcon1,
        'functionName': 'getUnpaidEth',
        'abi': kittpadABI,
        'contractAddress': '0xea36def1F65c9a807F7b5b26ef26000106895668',
    }
]

export default Claimdata;