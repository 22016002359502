import styled from "styled-components";

// import headingShape from "assets/images/bg/shape-bg.png";
// import cardBackShape from "assets/images/bg/staking_flip_card_blur_img.png";

const StakingItemStyleWrapper = styled.div`

 .card{
    margin-bottom: 10px;
    margin-top: 10px;
    background: #000000;
    cursor: pointer;
 }

 .title{
    margin-bottom: 10px;
 }

 .nftFail{
   color: #e38241;
   padding-top: 20px;
   white-space: nowrap;
 }

 .selected{
   border: 2px solid #e38241;
 }

 .stakeCard{
   margin-bottom: 30px;
 }

`;


export default StakingItemStyleWrapper;
