import styled from "styled-components";
import bannerBG from "assets/images/bg/bg.png";
const IncubatorListItem1StyleWrapper = styled.div`
    .master1{
        position: relative;
    }

    .divIcon{
        position: absolute;
        top: 40%; 
        left: 40%;
        z-index: 1;
    }

    .master {
        background: url(${bannerBG});
        text-align: center;
        width: 100%;
        height: 300px;
        position: relative;
        border-radius: 10px;

        .child1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #000000;
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0px;
            border-radius: 0px 0px 10px 10px;
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 30px;
        }

        .child2{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
            flex-basis: 50%;
            width: 100%;
            height: 100%;
            p{
                margin: 0px !important;
            }
        }
    }
`;

export default IncubatorListItem1StyleWrapper;