import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { SectionTitle } from "components/sectionTitle";
import Button from "components/button";
import PartnerStyleWrapper from "./partner.style";
import rocketShape from "assets/images/icons/rocket.png"
import earthShape from "assets/images/icons/earthBg.svg"
import data from "assets/data/partner/dataV2";

const Partner = () => {
  const handleLogoClick = (url) => {
    // Open the URL in a new tab/window or handle navigation as needed
    window.open(url, '_blank');
  };

  return (
    <PartnerStyleWrapper>
      <div className="container">
        <SectionTitle title="Incubated Projects" subtitle="Incubated Projects" />
        <Tabs>
          <TabList>
            {data?.map((item, i) => (
              <Tab key={i}>
                <Button variant="outline" sm href="# " onClick={(e) => e.preventDefault()}>
                  {item.partnerType}
                </Button>
              </Tab>
            ))}
            
          </TabList>
          {data?.map((partners, i) => (
            <TabPanel key={i} className="tabs-row partner_logo_list">
              {partners.logos?.map((logo, i) => (
                <span key={i} className="partner_logo" onClick={() => handleLogoClick(logo.url)}>
                  <img src={logo.image} alt="partner thumb" />
                </span>
              ))}
            </TabPanel>
          ))}
        </Tabs>
      </div>


    </PartnerStyleWrapper>
  );
};

export default Partner;
