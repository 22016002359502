import kittpadAbi from "assets/data/abi/kittpadABI";
import MeowTokenABI from "../abi/MeowTokenABI";

const dataOther = [
    {

        'title': 'Balance Breadown',
        'category1Title': 'Unstaked Tokens',
        'value1': 'Connect Wallet',
        'category2Title': 'Staked Tokens',
        'value2': 'Connect Wallet',
        'function2Name': 'getShares',
        'abi2': kittpadAbi,
        'address2': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'function1Name': 'balanceOf',
        'abi1': MeowTokenABI,
        'address1': '0x74d2d73B455540B037298c0e0925BC702AEDbE4a',
    },

    {
        'title': 'Percentage',
        'category1Title': '% Supply',
        'value1': 'Connect Wallet',
        'category2Title': 'Staked Tokens',
        'value2': 'Connect Wallet',
        'function2Name': 'getShares',
        'abi2': kittpadAbi,
        'address2': '0xea36def1F65c9a807F7b5b26ef26000106895668',
        'function1Name': 'balanceOf',
        'abi1': MeowTokenABI,
        'address1': '0x74d2d73B455540B037298c0e0925BC702AEDbE4a',

    },


]

export default dataOther;