import ClaimList from "./ClaimList/ClaimList";
import ClaimStyleWrapper from "./Claim.style"

const Claim = ({ animation }) => {
    const mountedStyle = { animation: "inAnimation 500ms ease-in" };
    const unmountedStyle = {
        animation: "outAnimation 270ms ease-out",
        animationFillMode: "forwards"
    };
    return (
        <div style={animation ? mountedStyle : unmountedStyle}>
            <ClaimStyleWrapper>
                <ClaimList />
            </ClaimStyleWrapper>
        </div>
    )
}

export default Claim;