import IncubatorList from "./IncubatorList/IncubatorList";
import IncubatorStyleWrapper from "./Incubator.style"

const Incubator = ({animation}) => {
    const mountedStyle = { animation: "inAnimation 500ms ease-in" };
    const unmountedStyle = {
        animation: "outAnimation 270ms ease-out",
        animationFillMode: "forwards"
    };
    return (
        <div style={animation ? mountedStyle : unmountedStyle}>
            <IncubatorStyleWrapper>
                <IncubatorList />
            </IncubatorStyleWrapper>
        </div>
    )
}

export default Incubator;