import IncubatorListItem2StyleWrapper from "./IncubatorListItem2.style";

// import kittpadStakingAbi from "assets/data/abi/kittpadABI";
import TestKittpadABI from "assets/data/abi/TestKittpadABI";

import { useContractWrite, useContractRead, useAccount, } from 'wagmi'

import { useModal } from "utils/ModalContext";
import kittpadABI from "assets/data/abi/kittpadABI";

const IncubatorListItem2 = ({
    title,
    category1Title,
    value1,
    category2Title,
    value2,
    function1Name,
    function2Name,
    abi1,
    abi2,
    address1,
    address2,
    args1,
    args2,
    value3,

}) => {
    const { address, isConnected } = useAccount()

    const { tokenList } = useModal();

    if (!args1) args1 = [address]
    if (!args2) args2 = [address]

    const ReadData1 = useContractRead({
        address: address1,
        abi: abi1,
        functionName: function1Name,
        args: args1,
        watch: true,
        onSuccess(data) {
            // if (function2Name === 'totalNftStaked') {
            //     console.log("Total NFT's Staked ", data)
            // }
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const ReadData2 = useContractRead({
        address: address2,
        abi: abi2,
        functionName: function2Name,
        args: args2,
        watch: true,
        onSuccess(data) {
            if (function2Name === 'totalNftStaked') {
                console.log("Total NFT's Staked ", data)
            }
            console.log("Success")
        },
        onError(error) {
            console.log('Error', error)
        },

    })


    const WriteData1 = useContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadABI,
        functionName: 'claimRewardForKD',
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })


    if (isConnected || localStorage.getItem('isWalletConnected')) {
        value1 = Number(ReadData1.data).toString()
    }
    if (isConnected || localStorage.getItem('isWalletConnected')) {
        value2 = Number(ReadData2.data).toString()
    }

    return (
        <IncubatorListItem2StyleWrapper>
            <h2>{title}</h2>
            <div className="master">
                <div className="child1">
                    <ul>
                        <li className="categoryTitle">{category1Title}</li>
                        {title === 'Unclaimed' ?
                            <li className="value">
                                {value3?.map((item, index) => (
                                    <div className="claimValue">
                                        {item}
                                        <button disabled={!WriteData1.write} onClick={() => WriteData1.write?.({
                                            args: [tokenList[index]],
                                        })} className="claimButton">Claim</button>
                                        {WriteData1.isLoading && <div>Check Wallet</div>}
                                        {WriteData1.isSuccess && <div>Transaction: {JSON.stringify(WriteData1.data)}</div>}
                                    </div>
                                ))}
                            </li> : <li className="value">
                                {value1}
                            </li>
                        }
                    </ul>
                </div>
                {title !== 'Unclaimed' ?
                    <div className="child2">
                        <ul>
                            <li className="categoryTitle">{category2Title}</li>
                            <li className="value">
                                {value2}
                            </li>
                        </ul>
                    </div> : <></>
                }
            </div>
        </IncubatorListItem2StyleWrapper>
    )
}

export default IncubatorListItem2;