const ethereum = typeof window !== 'undefined' ? window.ethereum : '';

var accounts = []

// check if metamask extension is installed on the browser
export const isMetaMaskInstalled = () => {
    if (ethereum) return true;
    return false;
}

// connect to metakmask wallet
export const connectWallet = async () => {
    accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    console.log("Blobum", accounts)
    localStorage.setItem('isWalletConnected', true);
    localStorage.setItem('accounts', accounts);
    return accounts;
}

// connect to metakmask wallet
export const isAccountConnected = async () => {
    if (localStorage.getItem('isWalletConnected') === 'true') {
        return [localStorage.getItem(accounts), true]
    }
    return [null, false]
}


// disconnect metamask wallet
export const disconnectWallet = () => {
    localStorage.removeItem('connectionType')
    localStorage.removeItem('isWalletConnected');
    localStorage.removeItem('accounts');
    window.location.reload();
}

// check metamask on disconnect
if (isMetaMaskInstalled()) {
    ethereum.on('accountsChanged', () => {
        window.location.reload();
    });
}

// check metamask on connected
const onMetamaskconnect = async () => {
    const chainId = await getChainId();
    ethereum.on('connect', () => {
        console.log(chainId);
    });
}

// on chain change
const onChainChange = () => {
    ethereum.on('chainChanged', (_chainId) => {
        return parseInt(_chainId);
    });
}

const getChainId = async () => {
    const chainId = await ethereum.request({ method: 'eth_chainId' });

    return parseInt(chainId);
}


const isWalletConnected = () => {
    if (localStorage.getItem('isWalletConnected') === 'true') {
        return true
    }

    return false;
}


const connectWalletLocaly = () => {
    localStorage.setItem('isWalletConnected', true);
}

