import kittpadStakingAbi from "assets/data/abi/kittpadABI";
import ClaimListItem2StyleWrapper from "./ClaimListItem2.style";
import { useContractWrite, usePrepareContractWrite, useContractRead, useAccount, } from 'wagmi'

const ClaimListItem2 = ({
    title,
    category1Title,
    value1,
    category2Title,
    value2,
    function1Name,
    function2Name,
    abi1,
    abi2,
    address1,
    address2,
    args1,
    args2,

}) =>  {

    // KITPADD CONTRACT 0xea36def1F65c9a807F7b5b26ef26000106895668
    // NFT CONTRACT 0x46084F00dD87B2f50c1E898399241E760D2284E3
    // TOKEN CONTRACT 0x74d2d73B455540B037298c0e0925BC702AEDbE4a

    // const { data: walletClient } = useWalletClient()

    const { address, isConnected } = useAccount()

    if(!args1) args1 = [address]
    if(!args2) args2 = [address]

    const ReadData1 = useContractRead({
        address: address1,
        abi: abi1,
        functionName: function1Name,
        args: args1,
        watch: true,
        onSuccess(data) {
            // if (function2Name === 'totalNftStaked') {
            //     console.log("Total NFT's Staked ", data)
            // }
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const ReadData2 = useContractRead({
        address: address2,
        abi: abi2,
        functionName: function2Name,
        args: args2,
        watch: true,
        onSuccess(data) {
            if (function2Name === 'totalNftStaked') {
                console.log("Total NFT's Staked ", data)
            }
            console.log("Success")
        },
        onError(error) {
            console.log('Error', error)
        },

    })


    const config1  = usePrepareContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadStakingAbi,
        functionName: 'claimRewardForNft',
        onError(error) {
            console.log('Write Error', error)
        },
        onSuccess(data) {
            console.log('Write Success', data)
        },
    })

    const WriteData1 = useContractWrite(config1.config)

    const config2  = usePrepareContractWrite({
        address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
        abi: kittpadStakingAbi,
        functionName: 'claimRewardForNft',
    })

    const WriteData2 = useContractWrite(config2.config)

    if(isConnected || localStorage.getItem('isWalletConnected')){
        value1 = Number(ReadData1.data).toString()
    }
    if(isConnected || localStorage.getItem('isWalletConnected')){
        value2 = Number(ReadData2.data).toString()
    }

    // ReadData1.status !== 'success' ? value1 = 'Null' : value1 = Number(ReadData1.data).toString()
    // ReadData2.status !== 'success' ? value2 = 'Null' : value2 = Number(ReadData2.data).toString()

    return (
        <ClaimListItem2StyleWrapper>    
            <h2>{title}</h2>
            <div className="master">
                <div className="child1">
                    <ul>
                        <li className="categoryTitle">{category1Title}</li>
                        {title === 'Unclaimed' ?
                            <li className="value">
                                <div className="claimValue">
                                    {value1}
                                    <button disabled={!WriteData1.write} onClick={() => WriteData1.write?.()} className="claimButton">Claim</button>
                                    {WriteData1.isLoading && <div>Check Wallet</div>}
                                    {WriteData1.isSuccess && <div>Transaction: {JSON.stringify(WriteData1.data)}</div>}
                                </div>
                            </li> : <li className="value">
                                {value1}
                            </li>
                        }
                    </ul>
                </div>
                <div className="child2">
                    <ul>
                        <li className="categoryTitle">{category2Title}</li>
                        {title === 'Unclaimed' ?
                            <li className="value">
                                <div className="claimValue">
                                    {value2}
                                    <button disabled={!WriteData2.write} onClick={() => WriteData2.write?.()} className="claimButton">Claim</button>
                                    {WriteData2.isLoading && <div>Check Wallet</div>}
                                    {WriteData2.isSuccess && <div>Transaction: {JSON.stringify(WriteData2.data)}</div>}
                                </div>
                            </li> : <li className="value">
                                {value2}
                            </li>}
                    </ul>
                </div>
            </div>
        </ClaimListItem2StyleWrapper>
    )
}

export default ClaimListItem2;