import styled from "styled-components";

const PageSelectorStyleWrapper = styled.div`
    .encloser {
        background: black;
    }

    .selector-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px 0px 20px 0px;

        button{
            background: transparent;
            width: 100px;
            height: 60%;
            align-items: center;
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-right: 10px;
            border-radius: 5px;
        }

        .active{
            font-size: 20px;
            font-family: "Roboto";
            background: #feac53;
            color: black;
            font-weight: 600;
        }
        
        .inactive{
            font-weight: 600;
            font-size: 20px;
            font-family: "Roboto";
            color: white;
        }

        .inactive:hover{
            background: gray;
            transition-duration: 0.3s;
        }
    }

`;

export default PageSelectorStyleWrapper;