import ClaimListItem1 from "./ClaimListItem1/ClaimListItem1"
import ClaimListItem2 from "./ClamListItem2/ClaimListItem2"
import ClaimListStyleWrapper from "./ClaimList.style"
import data from "assets/data/claim/data"
import dataOther from "assets/data/claim/data-other"

const ClaimList = () => {
    return (
        <ClaimListStyleWrapper>
            <div className="container">
                <div className="row staking_items_row">
                    {data?.map((claim, i) => (
                        <div key={i} className="col-lg-4 col-md-4">
                            <ClaimListItem1 {...claim} />
                        </div>
                    ))}
                    {dataOther?.map((claim, i) => (
                        <div key={i} className="col-lg-4 col-md-4">
                            <ClaimListItem2 {...claim} />
                        </div>
                    ))}
                </div>
            </div>
        </ClaimListStyleWrapper>
    )
}

export default ClaimList;