const data = [
  {
    id: "1W6WV",
    title: "Home",
    url: "/",
  },


];

export default data;
