import { useModal } from "utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletModal.style";
import { isMetaMaskInstalled, connectWallet } from "lib/metamaskhandler";
import metamaskIcon from "assets/images/icons/meta-mask.png";
import coinBase from "assets/images/icons/coinbase.png";
import trustWalletIcon from "assets/images/icons/trust.png";
import walletConnect from "assets/images/icons/wallet.png";

import { mainnet} from "wagmi/chains";
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { useConnect}  from "wagmi"


const WalletModal = () => {
  const { walletModalHandle, handleMetamaskModal, handleWalletConnectModal, handleMetamaskModal2 } = useModal();

  const connector = new MetaMaskConnector({
    chains: [mainnet],
    options: {
      shimDisconnect: true,
    },
  })

  const { connect } = useConnect({
    chainId: mainnet.id
  })

  const handleMetamask = async (e) => {
    e.preventDefault()
    if (!isMetaMaskInstalled()) {
      handleMetamaskModal();
    } else {
      // const account = await connectWallet();
      connect({connector});
      handleMetamaskModal2()
    }
  }

  const handleWalletConnect = async (e) => {
    e.preventDefault()
    handleWalletConnectModal();
  }

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>CONNECT WALLET</h2>
              <p>
                Please select a wallet to connect to this Staking Platform
              </p>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="wallet_list">
                <a href="#" onClick={(e) => handleMetamask(e)}>
                  <img src={metamaskIcon} alt="Meta-mask-Image" />
                  MetaMask
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
              
                <a href="# "onClick={(e) => handleWalletConnect(e)}>
                  <img src={trustWalletIcon} alt="Trust-Image" />
                  Trust Wallet
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                <a href="# " onClick={(e) => handleWalletConnect(e)}>
                  <img src={walletConnect} alt="Wallet-Image" />
                  WalletConnect
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
              </div>
              <div className="modal_bottom_text">
                By connecting your wallet, you agree to our
                <a href="# ">Terms of Service</a>
                <a href="#">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
