const data = [
  {
    title: "What is Kittpad ?",
    text: "Kittpad is a staking platform where you can stake your Meow Tokens & Kitty NFTs for rewards",
  },
  {
    title: "How we can buy Meow ?",
    text: "You can buy the Meow Token here https://app.uniswap.org/swap?outputCurrency=0x74d2d73b455540b037298c0e0925bc702aedbe4a",
  },
  {
    title: "Why we should choose Kittpad ?",
    text: "To earn passive income on your Meow tokens and Kitty NFTs and Meow!!",
  },
  {
    title: "Where we can buy and sell Kitty NFts ?",
    text: "You can buy Kitty NFT's here https://www.kittydinger.com/",
  },
  {
    title: "What is the kittpad contract address ?",
    text: "0xea36def1F65c9a807F7b5b26ef26000106895668",
  },
];

export default data;
