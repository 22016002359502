import IncubatorListItem1 from "./IncubatorListItem1/IncubatorListItem1"
import IncubatorListItem2 from "./IncubatorListItem2/IncubatorListItem2"
import IncubatorListStyleWrapper from "./IncubatorList.style"
// import data from "assets/data/incubator/data"
import dataOther from "assets/data/incubator/data-other"

import { useContractRead, useAccount, useContractReads } from 'wagmi'

import stakeIcon1 from "assets/images/icons/meow.png"
import MeowTokenABI from "assets/data/abi/MeowTokenABI";
import TestKittpadABI from "assets/data/abi/TestKittpadABI";
import erc20Abi from "assets/data/abi/erc20ABI";

import { useModal } from "utils/ModalContext";
import kittpadABI from "assets/data/abi/kittpadABI"

const IncubatorList = () => {
    const { tokenList } = useModal();

    const { isConnected, address } = useAccount()

    const ercContracts = []

    for (let i of tokenList) {
        ercContracts.push(
            {
                'abi': erc20Abi,
                'address': i,
                'functionName': 'name',
            },
            {
                'abi': erc20Abi,
                'address': i,
                'functionName': 'symbol',
            },
            {
                'abi': erc20Abi,
                'address': i,
                'functionName': 'decimals',
            },
            {
                'abi': kittpadABI,
                'address': '0xea36def1F65c9a807F7b5b26ef26000106895668',
                'functionName': 'totalClaimed',
                'args': [i]
            },
            // 0x8E44597Dc9F69347670fFeae116dAAaA67c78b6D
            {
                'abi': kittpadABI,
                'address': '0xea36def1F65c9a807F7b5b26ef26000106895668',
                'functionName': 'getUnpaidToken',
                'args': [address, i]
            }

        )
    }

    const ReadData1 = useContractRead({
        address: '0x74d2d73B455540B037298c0e0925BC702AEDbE4a',
        abi: MeowTokenABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        onSuccess(data) {

        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const ReadDataMultiple = useContractReads({
        contracts: ercContracts,
        watch: true,
        onSuccess(data) {
            console.log('Success', data)
        },
    })

    var value1 = []
    var value2 = []
    var value3 = []

    if (isConnected || localStorage.getItem('isWalletConnected')) {
        value1 = [Number(ReadData1?.data) / (10**9)]
        value2 = []
        for (let i = 1; i < ReadDataMultiple?.data?.length; i += 5) {
            value2.push(`${ReadDataMultiple?.data[i + 2]?.result} ${ReadDataMultiple?.data[i]?.result}`)
        }
        value3 = []
        for (let i = 1; i < ReadDataMultiple?.data?.length; i += 5) {
            value3.push(`${ReadDataMultiple?.data[i + 3]?.result} ${ReadDataMultiple?.data[i]?.result}`)
        }
        if(value2.length === 0){
            value2 = ['0']
        }
        if(value3.length === 0){
            value3 = ['None']
        }
    }
    else{
        value1 = ['Connect Wallet']
        value2 = ['Connect Wallet']
        value3 = ['Connect Wallet']
    }

    const Incubatordata = [
        {
            'title': 'Your Tokens',
            'value': value1,
            'icon': stakeIcon1,
        },

        // Using Test Kitpad COntract 0x8E44597Dc9F69347670fFeae116dAAaA67c78b6D

        {
            'title': 'Total Claimed',
            'value': value2,
            'icon': stakeIcon1,
        },

        {
            'title': 'Unclaimed Rewards',
            'value': value3,
            'icon': stakeIcon1,
        }
    ]

    dataOther[2] = {
        'title': 'Unclaimed',
        'category1Title': 'Staking Rewards',
        'value3': value3,

    }

    return (
        <IncubatorListStyleWrapper>
            <div className="container">
                <div className="row staking_items_row">
                    {Incubatordata?.map((Incubator, i) => (
                        <div key={i} className="col-lg-4 col-md-4">
                            <IncubatorListItem1 {...Incubator} />
                        </div>
                    ))}
                    {dataOther?.map((Incubator, i) => (
                        <div key={i} className="col-lg-4 col-md-4">
                            <IncubatorListItem2 {...Incubator} />
                        </div>
                    ))}
                </div>
            </div>
        </IncubatorListStyleWrapper>
    )
}

export default IncubatorList;
