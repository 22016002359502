import Counter from "components/counter";
import Button from "components/button";

import rocketThumb from "assets/images/homeV3/animition_avater.png";
import dollorIcon from "assets/images/icons/dollar-dollar-color.svg"
import rocketIcon from "assets/images/icons/rocket-dynamic-color.svg"
import ufoShape from "assets/images/homeV3/Ellipse.png"

import coinGecko from "assets/CoinGecko_logo.png"
import etherScan from "assets/etherscan-logo.png"
import opensea from "assets/opensea.svg"

import BannerStyleWrapper from "./Banner.style";
const Banner = () => {
  return (
    <>
      <BannerStyleWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="gamfi_v2_hero_left">
                <h2>Kittpad <br /><span style={{color:"#F2F2F2"}}> Stake-TO</span>
                  <img className="title_icon" src={dollorIcon} alt="icon" />
                  EARN
                </h2>
                <p>
                The web3 space is notorious for rugs, scams, and fraud - that’s where Kittpad comes in. While helping new projects launch, the Meow incubator launchpad provides its holders with early access to safe, innovative projects with a high earning potential. The team thoroughly vets each and every project, with strict measures taken to ensure the credibility of the developers and teams. The Meow team has already seen massive success with two incubated projects, which are $Vetme and $IT, and there will be more to follow
                </p>
                <div className="banner-btns">
                  <Button href="https://theschrodinger.com/" target="_blank" md variant="mint">
                    View Mainpage
                  </Button>
                  {/* <Button href="/" md variant="outline">
                    Stake
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="gamfi_v2_hero_right">
                <div className="gamfi_v2_hero_thumb">
                  <span className="rocket_thumb">
                    <img src={rocketThumb} alt="Rocket thumb" height="300px" width="250px"/>
                  </span>
                </div>

                <ul className="coin_cap_list">
                  <li>
                    {/* <h5>Market Cap</h5>
                    <h6 className="counter-number">$
                      <Counter
                        end={471.95}
                        decimal="."
                        decimals={490.88 % 1 !== 0 ? "2" : "0"}
                        suffix="K"
                      />
                    </h6> */}
                    <a href="https://www.coingecko.com/en/coins/schrodinger"><img className='iconImage' src={coinGecko} alt='coin gecko'/></a>
                  </li>
                  <li>
                    {/* <h5>TVL</h5>
                    <h6 className="counter-number">$
                      <Counter
                        end={109.44}
                        decimal="."
                        decimals={490.88 % 1 !== 0 ? "2" : "0"}
                        suffix="K"
                      />
                    </h6> */}
                    <a href="https://etherscan.io/token/0x74d2d73b455540b037298c0e0925bc702aedbe4a"><img className='iconImage' src={etherScan} alt='etherscan'/></a>
                  </li>
                  <li>
                    {/* <h5>Rewards Distributed</h5>
                    <h6 className="counter-number">$
                      <Counter
                        end={200}
                        decimal="."
                        decimals={490.88 % 1 !== 0 ? "2" : "0"}
                        suffix="K"
                      /> 
                    </h6> */}
                    <a href="https://opensea.io/collection/kittydinger"><img className='iconImage' src={opensea} alt='opensea'/></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <img src={ufoShape} alt="ufo shape" className="ufo_shape" />
      </BannerStyleWrapper>
    </>
  );
};

export default Banner;
