import IncubatorListItem1StyleWrapper from "./IncubatorListItem1.style";

import { useContractRead, useAccount, useContractReads } from 'wagmi'


const IncubatorListItem1 = ({
    title,
    value,
    icon
}) => {

    const { isConnected } = useAccount()

    return (
        <IncubatorListItem1StyleWrapper>
            <div className="master1">
                <div className="divIcon">
                    <img src={icon} alt="icon" height="80px" width="80px"></img>
                </div>
                <div className="master">
                    <div className="child1">
                        <p>{title}</p>
                        <div className="child2">
                        {value?.map((item, index) => (
                            <p>{item}</p>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </IncubatorListItem1StyleWrapper>
    )
}

export default IncubatorListItem1;