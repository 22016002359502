import { Fragment } from "react";
import { useModal } from "utils/ModalContext";
import GlobalStyles from "assets/styles/GlobalStyles"
import Layout from "components/layout";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Header from "sections/Header/v1";
import Banner from "sections/Banner/v3";
import Token from "sections/Token/v1";
import ExploreProjects from "sections/Projects/v5";
import Tutorial from "sections/Tutorial/v2";
import Features from "sections/Features/v2";
import Partner from "sections/Partner/v2";
import Faq from "sections/Faq/v2";
import Footer from "sections/Footer/v1";
import Staking from "sections/StakingPages/v3";
import Claim from "sections/ClaimPage";
import Incubator from "sections/IncubatorPage";
import PageHeader from "sections/StakingPages/v3/PageHeader";

import PageSelector from "sections/PageSelector";

import { useState, useEffect } from "react";

function useDelayUnmount(isMounted, delayTime) {
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

export default function HomeThree() {
  const { walletModalvisibility, metamaskModal, page, } = useModal();
  const showDiv = useDelayUnmount(page, 500);

  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        <Banner />
        <PageSelector/>
        {page === 'staking' && showDiv && <PageHeader currentPage="staking" pageTitle="Token STAKING" /> && <Staking animation={page}/>}
        {page === 'claim' && showDiv && <Claim animation={page}/>}
        {page === 'incubator' && showDiv && <Incubator animation={page}/>}
        <Partner/>
        <Faq/>
        <Footer />
      </Layout>
    </Fragment>
  );  
}
