import styled from "styled-components";
import bannerBG from "assets/images/bg/background.png";

const ClaimStyleWrapper = styled.div`
  background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
  padding: 30px 0;

  .page_inner_row {
    margin-top: 30px;
  }
`;

export default ClaimStyleWrapper;