import StakingList from "./StakingList/StakingList"
import StakingStyleWrapper from "./Staking.style"

const Staking = ({ animation }) => {
    const mountedStyle = { animation: "inAnimation 500ms ease-in" };
    const unmountedStyle = {
        animation: "outAnimation 270ms ease-out",
        animationFillMode: "forwards"
    };
    return (
        <div style={animation ? mountedStyle : unmountedStyle}>
            <StakingStyleWrapper>
                <StakingList />
            </StakingStyleWrapper>
        </div>
    )
}

export default Staking;