import styled from "styled-components";
import bannerBG from "assets/images/bg/bg.png";
const ClaimListItem1StyleWrapper = styled.div`
    .master1{
        position: relative;
    }

    .divIcon{
        position: absolute;
        top: 40%; 
        left: 40%;
        z-index: 1;
    }

    .master {
        background: url(${bannerBG});
        text-align: center;
        width: 100%;
        height: 300px;
        position: relative;
        border-radius: 10px;

        .child1{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: #000000;
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0px;
            border-radius: 0px 0px 10px 10px;
        }
    }
`;

export default ClaimListItem1StyleWrapper;