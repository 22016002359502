import { useState, useEffect } from "react";
import { ModalContext } from "./ModalContext";

import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useContractRead } from "wagmi"

import TestKittpadAbi from "assets/data/abi/TestKittpadABI";
import kittpadABI from "assets/data/abi/kittpadABI";


const ContextProvider = ({ children }) => {
  const [walletModalvisibility, setModalvisibility] = useState(false);
  const [shareModalVisibility, setShareModalvisibility] = useState(false);
  const [metamaskModal, setMetamaskModal] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [walletState, setWalletState] = useState(null);
  const [page, setPage] = useState("claim");
  const [tokenList, setTokenList] = useState(null);

  const { isDisconnected, isConnected } = useAccount()


  useEffect(() => {
    if (isDisconnected) {
      localStorage.removeItem('connectionType')
      setWalletState(null);
    }
    if (isConnected) {
      if (localStorage.getItem('wagmi.wallet') === '"walletConnect"') {
        localStorage.setItem('connectionType', 'walletconnect');
      }
      else {
        localStorage.setItem('connectionType', 'metamask');
      }
      setWalletState(localStorage.getItem('connectionType'));
    }

    // const walletType = localStorage.getItem('connectionType');
    // if(walletType){
    //   setWalletState(walletType);
    // }
  }, [isConnected, isDisconnected])

  // useEffect(() => {
  //   console.log("IsConnecting", isConnecting, "IsConnected", isConnected, "IsDisconnected", isDisconnected)
  // }, [isConnecting, isConnected, isDisconnected])

  const PRTokenRead = useContractRead({
    address: '0xea36def1F65c9a807F7b5b26ef26000106895668',
    abi: kittpadABI,
    functionName: 'getPrTokenList',
    watch: true,
    onSuccess(data) {
      console.log('List is', data)
      setTokenList(data)
    },
    onError(error) {
      console.log('Error', error)
    },
  })

  const handlePageChange = (page) => {
    setPage(page);
    console.log(page);
  }

  const { open } = useWeb3Modal();

  const handleAccountConnect = (acc) => {
    setAccounts(acc);
  };

  const walletModalHandle = () => {
    setModalvisibility(!walletModalvisibility);
  };

  const shareModalHandle = (e) => {
    e.preventDefault();
    setShareModalvisibility(!shareModalVisibility);
  };

  const handleMetamaskModal = () => {
    setModalvisibility(!walletModalvisibility);
    setMetamaskModal(!metamaskModal);

  };

  const handleMetamaskModal2 = () => {
    setModalvisibility(!walletModalvisibility);
    setWalletState("metamask")
    localStorage.setItem('connectionType', "metamask");
  }

  const handleWalletConnectModal = () => {
    setModalvisibility(!walletModalvisibility);
    open()
  }

  return (
    <ModalContext.Provider
      value={{
        walletModalHandle,
        walletModalvisibility,
        shareModalVisibility,
        shareModalHandle,
        metamaskModal,
        handleMetamaskModal,
        handleMetamaskModal2,
        handleWalletConnectModal,
        handleAccountConnect,
        accounts,
        walletState,
        handlePageChange,
        page,
        setWalletState,
        tokenList
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ContextProvider;
